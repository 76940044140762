import { useNavigate } from 'react-router-dom';
import { DashboardCard } from '@/components/DashboardCard';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Input, Button } from 'react-daisyui';

interface Props {
  searchText: string;
  setSearchText: (searchText: string) => void;
  children: ReactNode;
  showAllReports?: boolean;
  title: string;
  placeholder: string;
}

export function InvestorReportingBaseTableView({
  searchText,
  setSearchText,
  children,
  showAllReports,
  title,
  placeholder,
}: Props) {
  const navigate = useNavigate();

  return (
    <DashboardCard className='p-4' cardBodyclassName='p-0' hasMininumWidth>
      <div className='mt-3 mb-1'>
        <div className='text-lg font-bold mb-3'>
          <h2>{title}</h2>
        </div>
        <div className='relative flex text-gray-600 focus-within:text-gray-400'>
          <div className='flex justify-between w-full'>
            <div className='relative flex'>
              <span className='absolute inset-y-0 left-[260px] flex items-center pl-2'>
                <button
                  type='submit'
                  className='p-1 focus:outline-none focus:shadow-outline'
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </span>
              <Input
                data-testid='client-search-input'
                className='border-accent w-[300px]'
                placeholder={placeholder}
                size='sm'
                defaultValue={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />
            </div>

            <div className='ml-auto'>
              {showAllReports ? (
                <Button
                  color='accent'
                  size='sm'
                  className=''
                  onClick={() => navigate('/i-new-reporting')}
                >
                  View all Updates
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        {children}
      </div>
    </DashboardCard>
  );
}
