import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Select as DaisySelect } from 'react-daisyui';
import { DashboardCard, LoadingCard } from '@/components/DashboardCard';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { InvestorReportTemplateDTO, JWTFirm } from '@types';
import Select from 'react-select';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '@/components/Spinner';
import { get, post } from '@/utils/queries';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { fileOptions } from '@/pages/InvestorReporting/components/modals/utils';
import { GenericModal } from '@/components/Modal';
import { ReportsFrequencies } from '../../../../../../shared/constants';

interface SelectValue {
  value: number;
  label: string;
}

interface SelectCardOptions {
  title: string;
  description: string;
}

type FormState = {
  selectedFirm: JWTFirm | null;
  selectedClients: SelectValue[];
  selectedFrequency: ReportsFrequencies;
  selectedNewFileType: SelectCardOptions | null;
  cards: SelectCardOptions[];
  startingDate: number;
  existingTemplates: InvestorReportTemplateDTO[];
};

export default function RequestBuilder({
  editTemplate,
  onSave,
}: {
  editTemplate?: InvestorReportTemplateDTO;
  onSave: () => Promise<void>;
}) {
  const { auth }: IUserContext = useUserContext();
  const firms = useMemo(() => auth?.firms || [], [auth]);
  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [repeatedTemplates, setRepeatedTemplates] = useState<
    InvestorReportTemplateDTO[]
  >([]);
  const [candidateClients, setCandidateClients] = useState<SelectValue[]>([]);
  const {
    data: templates,
    isValidating: isLoadingTemplates,
    mutate,
  } = get<InvestorReportTemplateDTO[]>('investorReports/templates');

  const getClientValueFromTemplate = () => {
    const currentClient = auth?.clients?.find(
      (client) => client.id === editTemplate?.client_id
    );
    return {
      value: currentClient?.id,
      label: currentClient?.client_name,
    };
  };
  const getFirm = () => {
    if (editTemplate?.firm) {
      return (
        auth?.firms.find((firm) => firm.id === editTemplate?.firm?.id) || null
      );
    }
    if (firms.length === 1) {
      return firms[0];
    }
    return null;
  };
  const [formState, setFormState] = useState<FormState>({
    selectedFirm: getFirm(),
    selectedClients: editTemplate
      ? ([getClientValueFromTemplate()] as SelectValue[])
      : ([] as SelectValue[]),
    selectedFrequency: ReportsFrequencies.monthly,
    selectedNewFileType: null as SelectCardOptions | null,
    cards: [] as SelectCardOptions[],
    existingTemplates: [] as InvestorReportTemplateDTO[],
    startingDate: 31,
  });

  const clientOptions = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    if (firms.length > 0) {
      return (formState.selectedFirm?.clients || auth?.clients)
        ?.filter((client) => !!client)
        .filter((client) => auth?.clients?.find((c) => c.id === client.id))
        .map((client) => ({
          value: client.id,
          label: client.client_name,
        }));
    }
    return auth?.clients.map((client) => ({
      value: client.id,
      label: client.client_name,
    }));
  }, [auth, formState.selectedFirm, firms.length]);

  const updateFormState = <K extends keyof FormState>(
    key: K,
    value: FormState[K]
  ) => {
    setFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  useEffect(() => {
    const editClient = clientOptions?.find(
      (client) => client.value === editTemplate?.client_id
    );
    const editFirm = firms.find((firm) => firm.id === editTemplate?.firm_id);
    const editCards = (editTemplate?.investor_report_card_templates || []).map(
      (card) => ({ title: card.title, description: card.description })
    );
    if (editClient) {
      setFormState((prevState) => ({
        ...prevState,
        selectedClients: [editClient],
        selectedFirm: editFirm || null,
        cards: editCards,
        startingDate: editTemplate?.starting_date || 31,
        selectedFrequency:
          editTemplate?.frequency || ReportsFrequencies.monthly,
      }));
    } else if (!editTemplate && editTemplate) {
      setFormState((prevState) => ({
        ...prevState,
        selectedClients: [],
        selectedFirm: editFirm || null,
        cards: editCards,
        startingDate: 31,
        selectedFrequency: ReportsFrequencies.oneTime,
      }));
    }
  }, [editTemplate, clientOptions, firms]);

  const availableFileOptions = useMemo(() => {
    const cardsLabels = formState.cards.map((card) => card.title);
    return fileOptions.filter((file) => !cardsLabels.includes(file.title));
  }, [formState.cards]);

  const frequencies = [
    { index: 0, label: ReportsFrequencies.monthly },
    { index: 1, label: ReportsFrequencies.quarterly },
    { index: 2, label: ReportsFrequencies.annual },
    { index: 3, label: ReportsFrequencies.oneTime },
  ];

  const { Option } = DaisySelect;

  const addCard = () => {
    if (!formState.selectedNewFileType) {
      return;
    }
    setFormState((prevState) => ({
      ...prevState,
      cards: [
        ...prevState.cards,
        formState.selectedNewFileType as SelectCardOptions,
      ],
      selectedNewFileType: null,
    }));
  };

  const removeCard = (index: number) => {
    const newCards = [...formState.cards];
    newCards.splice(index, 1);
    updateFormState('cards', newCards);
  };

  const checkDisabled = () =>
    formState.selectedClients.length === 0 ||
    formState.cards.length === 0 ||
    (firms.length > 0 && !formState.selectedFirm);

  const saveData = async () => {
    try {
      setIsSaving(true);
      const body = {
        clientsIds: formState.selectedClients.map((client) => client.value),
        // descriptionList: cards.map((card) => card.title),
        cardsData: formState.cards.map((card) => {
          return { title: card.title, description: card.description };
        }),
        firmId: firms.length > 0 ? formState.selectedFirm?.id : undefined,
        frequency: formState.selectedFrequency.valueOf(),
        startingDate:
          formState.selectedFrequency === ReportsFrequencies.monthly
            ? formState.startingDate
            : undefined,
      };

      if (formState.selectedFrequency === ReportsFrequencies.oneTime) {
        await post(`investorReports/oneTimeRequest`, body);
      } else {
        await post(`investorReports/templates`, body);
      }
      alertMessageSuccess('Report templates created successfully');
      setFormState((prevState) => ({
        ...prevState,
        selectedFirm: null,
        selectedClients: [],
        selectedFrequency: ReportsFrequencies.monthly,
        cards: [],
        startingDate: 31,
        existingTemplates: [],
      }));
      await mutate();
      setShowModal(false);
      await onSave();
    } catch (err) {
      alertErrorMessage('Something went wrong, please try again!');
    } finally {
      setShowModal(false);
      setIsSaving(false);
    }
  };

  const onClick = async () => {
    if (checkDisabled()) {
      return;
    }
    const selectedClientsIds = formState.selectedClients.map(
      (client) => client.value
    );

    const firmId = firms.length > 0 ? formState.selectedFirm?.id : null;
    const frequency = formState.selectedFrequency.valueOf();
    const candidateTemplatesFound =
      auth?.clients
        .filter((c) => !selectedClientsIds.includes(c.id))
        .map((client) => ({
          value: client.id,
          label: client.client_name,
        })) || [];

    const repeatedTemplatesFound = templates?.filter(
      (template) =>
        selectedClientsIds.includes(template.client_id) &&
        template.frequency === frequency &&
        (template.firm_id === firmId || template.user_id === auth?.id)
    );

    let readyToSave = true;
    if (
      repeatedTemplatesFound &&
      repeatedTemplatesFound.length > 0 &&
      formState.selectedFrequency !== ReportsFrequencies.oneTime
    ) {
      readyToSave = false;
      setRepeatedTemplates(repeatedTemplatesFound);
      setShowModal(true);
    }

    if (
      candidateTemplatesFound &&
      candidateTemplatesFound.length > 0 &&
      formState.selectedFrequency !== ReportsFrequencies.oneTime
    ) {
      readyToSave = false;
      setCandidateClients(candidateTemplatesFound);
      setShowModal(true);
    }
    if (readyToSave) {
      await saveData();
    }
  };

  const handleSelectClient = (client: SelectValue) => {
    if (formState.selectedClients.find((c) => c === client)) {
      setFormState((prevState) => ({
        ...prevState,
        selectedClients: [
          ...prevState.selectedClients.filter((c) => c.value !== client.value),
        ],
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        selectedClients: [...prevState.selectedClients, client],
      }));
    }
  };

  if (isLoadingTemplates) {
    return <LoadingCard />;
  }

  return (
    <DashboardCard data-testid='investor-report-builder'>
      {showModal ? (
        <GenericModal onClose={() => setShowModal(false)} isOpen={showModal}>
          <div className='flex h-full flex-col'>
            <div className='flex items-center'>
              <div className='w-full'>
                <div className='font-bold text-base'>
                  {repeatedTemplates.length > 0 ? (
                    <div>
                      This will overwrite the {formState.selectedFrequency}{' '}
                      templates for:
                      {repeatedTemplates.map((template) => (
                        <div
                          key={`template_${template.id}`}
                          className='font-normal'
                        >
                          <br />
                          {
                            auth?.clients.find(
                              (client) => client.id === template.client_id
                            )?.client_name
                          }
                        </div>
                      ))}
                      <br />
                    </div>
                  ) : null}
                  {candidateClients.length > 0 ? (
                    <div>
                      Do you want to apply the following templates other
                      companies in your porfolio (this will overwrite the{' '}
                      {formState.selectedFrequency} templates if they have one):
                      <br />
                      <br />
                      {candidateClients.map((c) => (
                        <div
                          key={`template_${c.value}`}
                          className='font-normal'
                        >
                          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                          <label
                            className='flex items-center cursor-pointer'
                            htmlFor='enable-tab'
                            key='label-tab-all-users'
                            onClick={() => handleSelectClient(c)}
                          >
                            <input
                              type='checkbox'
                              key='checkbox-all-users'
                              className='form-checkbox h-5 w-5 text-blue-600'
                              data-testid='checkbox-all-users'
                              checked={formState.selectedClients.includes(c)}
                            />
                            <h3 className='text-base ml-2 break-all max-w-xs'>
                              {c.label}
                            </h3>
                          </label>
                          <br />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className='font-bold text-base' />
                <br />
                <div className='flex justify-center'>
                  <Button
                    color='accent'
                    variant='outline'
                    size='sm'
                    className='mr-2'
                    onClick={() => setShowModal(false)}
                    disabled={isSaving}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    title='confirm'
                    color='accent'
                    size='sm'
                    className='btn btn-accent'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={saveData}
                    disabled={isSaving}
                  >
                    Confirm
                    {isSaving && (
                      <Spinner className='mx-auto w-4 text-base ml-4' />
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <br />
          </div>
        </GenericModal>
      ) : null}

      <h2 className='text-base uppercase font-bold'>
        Recurring reports builder
      </h2>

      <div className='text-base'>
        {firms.length > 1 && !editTemplate ? (
          <>
            <br />
            <div className='font-bold text-base'>Firm requesting reports:</div>
            <DaisySelect
              value={formState.selectedFirm ? formState.selectedFirm?.id : 0}
              className='select select-bordered select-accent w-full mt-4'
              onChange={(selectedFirmId: number) => {
                updateFormState(
                  'selectedFirm',
                  firms?.find(
                    (firm) => firm.id === Number(selectedFirmId)
                  ) as JWTFirm
                );
                updateFormState('selectedClients', []);
              }}
            >
              <Option value={0} disabled>
                Select a firm
              </Option>
              {
                firms?.map((firm) => (
                  <Option
                    key={`firm_${firm.firm_name}`}
                    value={String(firm.id)}
                  >
                    {firm.firm_name}
                  </Option>
                )) as unknown as ReactElement
              }
            </DaisySelect>
            <br />
          </>
        ) : null}

        <div className='flex flex-wrap items-center pb-2 mt-4'>
          {!editTemplate ? (
            <>
              <div className='font-bold text-base'>Select Clients:</div>
              <br />
              <div className='font-bold text-sm w-[100%] text-left'>
                <Select
                  isMulti
                  className='react-select-container border-accent'
                  classNamePrefix='react-select'
                  placeholder='select clients ...'
                  closeMenuOnSelect={false}
                  options={clientOptions}
                  value={formState.selectedClients}
                  isDisabled={!formState.selectedFirm && firms.length > 1}
                  onChange={(value) =>
                    updateFormState('selectedClients', value as SelectValue[])
                  }
                  id='selectclients'
                />
                {!formState.selectedClients?.length &&
                  formState.selectedFirm && (
                    <Alert
                      icon={<ExclamationTriangleIcon className='w-5 h-5' />}
                      className='my-2 text-sm'
                    >
                      Reports should have at least one client assigned.
                    </Alert>
                  )}
              </div>
            </>
          ) : null}
        </div>

        <br />

        {!editTemplate ? (
          <>
            <div className='flex flex-row items-center'>
              <div> Frequency:</div>
              <div className='ml-2'>
                {frequencies.map((data) => {
                  return (
                    <span key={`${data.index + 1}_frequency_span`}>
                      <Button
                        size='sm'
                        onClick={() =>
                          updateFormState('selectedFrequency', data.label)
                        }
                        className={`${
                          data.label === formState.selectedFrequency
                            ? 'btn-active'
                            : ''
                        }`}
                        key={`${data.index + 1}_frequency_button`}
                      >
                        {data.label}
                      </Button>
                    </span>
                  );
                })}
              </div>
            </div>

            <br />
          </>
        ) : null}

        {formState.selectedFrequency === ReportsFrequencies.monthly ? (
          <>
            <div className='flex flex-row items-center'>
              <div> Request Send Date:</div>
              <DaisySelect
                value={formState.startingDate}
                disabled={
                  formState.selectedFrequency !== ReportsFrequencies.monthly
                }
                className='select select-bordered select-accent w-[15%] ml-4'
                onChange={(date: number) => {
                  updateFormState('startingDate', date);
                }}
              >
                {Array.from({ length: 31 }, (_, i) => i + 1).map((i) => (
                  <Option key={`day_${i}`} value={i}>
                    {i}
                  </Option>
                ))}
              </DaisySelect>
            </div>

            <br />
          </>
        ) : null}

        <div className='flex flex-wrap'>
          {formState.cards.map((card, cardIndex) => (
            <div
              key={`card-${card.title}`}
              className='items-center my-2 mx-2 shadow card rounded-lg dropdown-content 
               text-gray-300 w-[45%] btn-accent justify-center' // bg-gray-600
            >
              <FontAwesomeIcon
                color='#d3d3d3'
                icon={faCircleXmark}
                data-testid='remove-card-button'
                size='xl'
                onClick={() => removeCard(cardIndex)}
                className='pt-1 pb-1 cursor-pointer z-10 absolute'
                style={{ right: '-10px', top: '-10px' }}
              />
              <div key={`card-${card.title}`} className='my-2 mx-2'>
                <div className='my-2 mx-2 text-center'>
                  <span className='font-bold'>{card.title}</span>
                  <div>
                    <span>{card.description}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <br />

        <div className='items-center p-0 my-0 shadow card rounded-lg dropdown-content bg-gray-600 text-gray-300'>
          <div className='my-4 w-[50%]  '>
            <div>
              <DaisySelect
                value={
                  formState.selectedNewFileType
                    ? formState.selectedNewFileType?.title
                    : 'default'
                }
                disabled={availableFileOptions.length === 0}
                className='select select-bordered select-accent w-full'
                onChange={(title: string) => {
                  updateFormState(
                    'selectedNewFileType',
                    fileOptions?.find(
                      (option) => option.title === title
                    ) as SelectCardOptions
                  );
                }}
                data-testid='select-file-type'
              >
                <Option value='default' disabled>
                  Select files to request
                </Option>

                {
                  availableFileOptions?.map((option) => (
                    <Option
                      key={`file-type-option-${option.title}`}
                      value={String(option.title)}
                    >
                      {option.title}
                    </Option>
                  )) as unknown as ReactElement
                }
              </DaisySelect>
            </div>
            <div>
              <Button
                type='submit'
                title='login'
                color='accent'
                size='sm'
                className='btn btn-accent bg-transparentp-2 m-2 ml-0'
                data-testid='add-user-investor-button'
                disabled={!formState.selectedNewFileType}
                onClick={() => {
                  addCard();
                }}
              >
                Add Request
              </Button>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className='flex justify-center'>
        <Button
          type='submit'
          title='login'
          color='accent'
          size='sm'
          className='btn btn-accent'
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onClick}
          disabled={checkDisabled() || isSaving}
        >
          Confirm
          {isSaving && <Spinner className='mx-auto w-4 text-base ml-4' />}
        </Button>
      </div>
    </DashboardCard>
  );
}
