import { Nav } from '@/components/Nav';
import MainMenu from '@/components/Nav/MainMenu';
import { useState } from 'react';
import { Client } from '@types';

// eslint-disable-next-line @typescript-eslint/no-use-before-define
export { DashboardWrapper };
export default function DashboardWrapper({
  children,
  companyName,
  companyData,
}: {
  children: React.ReactNode;
  companyName?: string;
  companyData?: Client[];
}) {
  const [menuOpen, setMenuOpen] = useState(true);
  const menuClick = () => setMenuOpen(!menuOpen);

  return (
    <div className='h-screen flex flex-col'>
      <Nav
        companyName={companyName}
        companyData={companyData}
        onMenuClick={menuClick}
        isMenuOpen={menuOpen}
      />
      <div className='w-full'>
        <div className='flex flex-row flex-wrap'>
          <aside
            className={`hidden md:flex min-h-[(calc(100vh-70px))] bg-[#454648] transition-all ${
              menuOpen ? `w-[240px]` : 'w-0'
            } overflow-hidden ease-in-out duration-300`}
          >
            <div className='w-[240px] overflow-hidden grid content-between'>
              <MainMenu />
            </div>
          </aside>
          <main
            role='main'
            className={`mx-auto ${
              menuOpen ? 'md:w-[calc(100vw-256px)]' : 'w-full'
            } pt-1 px-2 transition-all ease-in-out duration-300 h-[calc(100vh-100px)] overflow-y-scroll`}
          >
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}
