import { InvestorReportDTO } from '@types';
import { GenericModal } from '@/components/Modal';
import { Button } from 'react-daisyui';
import { useState } from 'react';

function SendReportNudgeModal({
  showModalStatus = false,
  onClose,
  companyName,
  onAcceptAction,
  overdueReports,
}: {
  showModalStatus: boolean;
  onClose: () => void;
  companyName: string;
  onAcceptAction: (data: InvestorReportDTO[]) => void;
  overdueReports: InvestorReportDTO[];
}) {
  const isMultiple = overdueReports.length > 1;
  const [selectedReports, setSelectedReports] = useState<InvestorReportDTO[]>(
    isMultiple ? [] : [overdueReports[0]]
  );

  const message = isMultiple
    ? 'Select the overdue data request to send a reminder:'
    : `Send a reminder to ${companyName} for the ${overdueReports[0].period} data request?`;

  const handleSelectReport = (report: InvestorReportDTO) => {
    if (isMultiple) {
      setSelectedReports((prev) => {
        if (prev.includes(report)) {
          return prev.filter((c) => c.id !== report.id);
        }
        return [...prev, report];
      });
    } else {
      setSelectedReports([report]);
    }
  };

  return (
    <GenericModal onClose={onClose} isOpen={showModalStatus}>
      <div className='flex h-full flex-col overflow-hidden'>
        <div className='flex items-center'>
          <div className='w-full'>
            <div className='font-bold text-base'>{message}</div>
            <br />

            {isMultiple
              ? overdueReports.map((c) => (
                  <div key={`template_${c.period}`} className='font-normal'>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <label
                      className='flex items-center cursor-pointer'
                      htmlFor='enable-tab'
                      key={`label-tab-${c.period}`}
                      onClick={() => handleSelectReport(c)}
                    >
                      <input
                        type='checkbox'
                        key='checkbox-all-users'
                        className='form-checkbox h-5 w-5 text-blue-600'
                        data-testid={`checkbox-tab-${c.period}`}
                        checked={selectedReports.includes(c)}
                        onChange={() => {}}
                      />
                      <h3 className='text-base ml-2 break-all max-w-xs'>
                        {c.client?.client_name} - {c.period}
                      </h3>
                    </label>
                    <br />
                  </div>
                ))
              : null}
            <br />
          </div>
        </div>
        <div className='flex justify-between'>
          <Button
            color='accent'
            variant='outline'
            size='sm'
            className='mr-2'
            onClick={() => onClose()}
            // disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            title='Send'
            color='accent'
            size='sm'
            className='btn btn-accent'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => onAcceptAction(selectedReports)}
            disabled={selectedReports.length === 0}
          >
            Confirm
          </Button>
        </div>
      </div>
    </GenericModal>
  );
}

export default SendReportNudgeModal;
