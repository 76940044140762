import { DashboardCard } from '@/components/DashboardCard';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvestorReportDTO, InvestorReportTemplateDTO } from '@types';
import { useState } from 'react';
import { Input } from 'react-daisyui';
import InvestorClientList from '@/pages/InvestorReporting/components/Investors/InvestorClientList';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { InvestorReportsListForClients } from './components/Clients/InvestorReportsListForClients';

interface Props {
  investorReports: InvestorReportDTO[];
  investorReportTemplates: InvestorReportTemplateDTO[];
  updateData: () => Promise<void>;
}

export function InvestorReportingManagement({
  investorReports,
  investorReportTemplates,
  updateData,
}: Props) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext();
  const [searchText, setSearchText] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(40);

  return (
    <DashboardCard className='p-4' cardBodyclassName='p-0' hasMininumWidth>
      <div className='mt-3 mb-1'>
        <div className='relative flex text-gray-600 focus-within:text-gray-400'>
          <div className='flex justify-between w-full'>
            <div className='relative flex'>
              <span className='absolute inset-y-0 left-[260px] flex items-center pl-2'>
                <button
                  type='submit'
                  className='p-1 focus:outline-none focus:shadow-outline'
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </span>
              <Input
                data-testid='client-search-input'
                className='border-accent w-[300px]'
                placeholder='Search Reports'
                size='sm'
                defaultValue={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />
            </div>
          </div>
        </div>

        {auth?.isInvestor ? (
          <InvestorClientList
            investorReports={investorReports || []}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
            searchText={searchText}
            investorReportTemplates={investorReportTemplates}
            updateData={updateData}
          />
        ) : null}
        {auth?.isClient || (impersonatingAccountId && auth?.isAdmin) ? (
          <InvestorReportsListForClients
            investorReports={investorReports || []}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
            searchText={searchText}
            investorReportTemplates={investorReportTemplates}
          />
        ) : null}
      </div>
    </DashboardCard>
  );
}
