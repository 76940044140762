import { fetcher } from '@/utils/queries';
import { InvestorReportDTO, InvestorReportTemplateDTO } from '@types';
import useSWR, { mutate } from 'swr';

function useInvestorReportsData() {
  const baseRoute = 'api/investorReports';

  const getInvestorReportsClientData = (
    clientId: number,
    reportId: number,
    userId?: number,
    firmId?: number
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR<InvestorReportDTO, Error>(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${baseRoute}/report/${reportId}?clientId=${clientId}&userId=${userId}&firmId=${firmId}`,
      fetcher
    );
  };

  const refreshInvestorReportsClientData = async (
    clientId: number,
    reportId: number,
    userId?: number,
    firmId?: number
  ) => {
    await mutate(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${baseRoute}/report/${reportId}?clientId=${clientId}&userId=${userId}&firmId=${firmId}`
    );
  };

  const getInvestorReportsTemplateData = (
    clientId: number,
    userId?: number,
    firmId?: number
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR<InvestorReportTemplateDTO[], Error>(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${baseRoute}/templates/client/${clientId}?userId=${userId}&firmId=${firmId}`,
      fetcher
    );
  };

  return {
    getInvestorReportsClientData,
    refreshInvestorReportsClientData,
    getInvestorReportsTemplateData,
  };
}

export default useInvestorReportsData;
