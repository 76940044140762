import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IUserContext, useUserContext } from '@/context/UserContext';
import type { JWTUser } from '@types';

import {
  BOTTOM_MENU_BY_USER,
  MENU_ADMIN_EXCLUSIVES,
  MENU_BY_USER,
  getIconDefinitionByName,
} from '@/components/Nav/utils';

import { MenuOption } from '@/types/menu-options';
import useAccountData from '@/hooks/useAccountData';
import useCustomTabsPermissions from '@/hooks/useCustomTabsPermissions';
import { useEffect, useState } from 'react';
import MainMenuItem from '@/components/Nav/MainMenuItem';
import 'react-toastify/dist/ReactToastify.css';
import InvestorsMenu from '@/components/Nav/InvestorsMenu';
import { CustomTabItem } from '../../../../shared/types/custom-tabs';

function MainMenu() {
  const [customTabInfo, setCustomTabInfo] = useState<CustomTabItem[] | null>(
    null
  );
  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([]);
  const [bottomMenuOptions, setBottomMenuOptions] = useState<MenuOption[]>([]);
  const { impersonatingAccountId, auth }: IUserContext = useUserContext();
  const { getAccountData } = useAccountData();
  const { useCustomTabs } = useCustomTabsPermissions();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, no-nested-ternary
  const { data } = impersonatingAccountId
    ? getAccountData(impersonatingAccountId)
    : auth?.clients?.[0]?.id
    ? getAccountData(auth?.clients?.[0]?.id)
    : { data: null };

  const clientId = auth?.isClient
    ? auth?.clients?.[0]?.id
    : impersonatingAccountId || undefined;

  const { data: customTabs, isLoading } = useCustomTabs(
    auth?.id as number,
    clientId
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const showTransactionsTab = !!data;

  useEffect(() => {
    if (customTabs) setCustomTabInfo(customTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, isLoading]);

  useEffect(() => {
    const menuType = () => {
      if (impersonatingAccountId && auth?.isAdmin) {
        return 'client';
      }
      return (auth as JWTUser)?.role || 'client';
    };

    const prepareCustomTabs: MenuOption[] = (customTabInfo || []).map(
      (customTab: CustomTabItem) => ({
        icon: getIconDefinitionByName(customTab.icon) as IconProp,
        label: customTab.titleOfTab,
        url: `/custom-tab-option/${encodeURIComponent(
          customTab.titleOfTab
        )}?embeddedLink=${encodeURIComponent(
          customTab.embeddedLink
        )}&documentLink=${encodeURIComponent(customTab.documentLink)}`,
      })
    );
    // Function to generate menu options
    const getMenuOptions = () => {
      let options = [
        ...MENU_BY_USER[menuType()],
        ...(auth?.isInvestor ? [] : prepareCustomTabs),
      ];

      // Conditionally add the Transactions tab
      if (
        ((auth?.isAdmin && impersonatingAccountId) || auth?.isClient) &&
        !!showTransactionsTab
      ) {
        options = [...options, ...MENU_ADMIN_EXCLUSIVES];
      }

      // Remove the /account option for investors when impersonating
      if (impersonatingAccountId && auth?.role === 'investor') {
        options = options.filter((option) => option.url !== '/account');
      }

      if (auth?.role === 'investor' && auth?.firms?.length === 0) {
        options = options.filter((option) => option.url !== '/i-account');
      }
      return options as MenuOption[];
    };
    const getBottomMenuOptions = () => {
      let options = [...BOTTOM_MENU_BY_USER[menuType()]];

      // Remove the /account option for investors when impersonating
      if (impersonatingAccountId && auth?.role === 'investor') {
        options = options.filter((option) => option.url !== '/account');
      }

      if (auth?.role === 'investor' && auth?.firms?.length === 0) {
        options = options.filter((option) => option.url !== '/i-account');
      }
      return options as MenuOption[];
    };
    const menu = getMenuOptions();
    const bottomMenu = getBottomMenuOptions();

    // Set the menu options without the action
    setMenuOptions(menu);
    setBottomMenuOptions(bottomMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonatingAccountId, showTransactionsTab, customTabInfo]);

  return (
    <>
      <div>
        <ul
          className='menu min-w-full overflow-hidden mt-5 p-1'
          id='MainMenu'
          role='menu'
        >
          {menuOptions.map((option) => (
            <li key={option.label} className='mt-2' role='menuitem'>
              <MainMenuItem {...option} />
            </li>
          ))}
        </ul>
        <InvestorsMenu />
      </div>
      {bottomMenuOptions.length > 0 ? (
        <ul
          className='menu min-w-full overflow-hidden mt-5 p-1 justify-center'
          id='MainMenu'
          role='menu'
        >
          {bottomMenuOptions.map((option) => (
            <li key={option.label} className='mt-2' role='menuitem'>
              <MainMenuItem {...option} />
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
}

export default MainMenu;
