import { signal } from '@preact/signals-react';
import { InvestorReportDTO } from '@types';
import { KeyedMutator } from 'swr';

export interface InvestorReportSignalData {
  reportData: InvestorReportDTO[] | undefined;
  isReportsLoading: boolean;
  reportsError: Error | undefined;
  mutateReports: KeyedMutator<InvestorReportDTO[]>;
}

const investorReportSignalData = signal<InvestorReportSignalData | null>(null);

export const setInvestorReportSignal = (
  data: InvestorReportSignalData | null
) => {
  investorReportSignalData.value = data;
};

export const getInvestorReportSignal = () => {
  return investorReportSignalData.value;
};
