/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useMemo, useState } from 'react';
import {
  faSquare,
  faSquareMinus,
  faSquareCheck,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { get } from '@/utils/queries';
import type { FileItem, FirmData, Folder, User, FirmClients } from '@types';
import { Auth } from '@/types';
import { UserValue } from '../share/SharePermission';

interface UseGrantPermissionProps {
  auth: Auth | null | undefined;
  clientId: number;
  folderOrFile: Folder | FileItem;
  fileUserIds?: number[];
  firmClientsData?: FirmClients[];
}

export const useGrantPermission = ({
  auth,
  clientId,
  folderOrFile,
  fileUserIds,
  firmClientsData,
}: UseGrantPermissionProps) => {
  const originallyAllowedUsers =
    folderOrFile.permissions
      ?.find((t) => t.fileId === folderOrFile.id)
      ?.allowed_users_by_user_name?.map((u) => u.user_id) || [];

  const originallyAllowedFirms =
    folderOrFile.permissions?.find((t) => t.fileId === folderOrFile.id)
      ?.allowedFirms || [];

  const [selectedUsers, setSelectedUsers] = useState<number[]>(
    originallyAllowedUsers
  );
  const [selectedFirms, setSelectedFirms] = useState<number[]>(
    originallyAllowedFirms
  );
  const [removedUsers, setRemovedUsers] = useState<number[]>([]);
  const [removedFirms, setRemovedFirms] = useState<number[]>([]);

  const { data: userData, isValidating: isLoading } = get<User[]>(
    `users/list/${clientId}`
  );

  const investorList = useMemo(() => {
    if (!auth || !userData) return [];

    const roleToFilterBy = 'investor';

    return userData
      .filter(
        ({ role, enabled, id }) =>
          role === roleToFilterBy && enabled && !fileUserIds?.includes(id)
      )
      .map(({ id, first_name, last_name, email_address }) => ({
        id,
        label: `${first_name} ${last_name}`,
        email: email_address,
      }));
  }, [userData, auth, fileUserIds]);

  const toggleUserSelection = (userId: number) => {
    if (
      originallyAllowedUsers.includes(userId) &&
      !removedUsers.includes(userId)
    ) {
      setRemovedUsers([...removedUsers, userId]);
    }

    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter((id) => id !== userId)
        : [...prevSelectedUsers, userId]
    );
  };

  const toggleFirmSelection = (firmId: number) => {
    if (
      originallyAllowedFirms.includes(firmId) &&
      !removedFirms.includes(firmId)
    ) {
      setRemovedFirms([...removedFirms, firmId]);
    }

    setSelectedFirms((prevSelectedFirms) =>
      prevSelectedFirms.includes(firmId)
        ? prevSelectedFirms.filter((id) => id !== firmId)
        : [...prevSelectedFirms, firmId]
    );
  };

  const handleShareAllUsers = () => {
    if (selectedUsers.length === investorList?.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(investorList?.map(({ id }) => id) || []);
    }
    setRemovedUsers(originallyAllowedUsers);
  };

  const handleShareAllFirms = () => {
    if (selectedFirms.length === firmClientsData?.length) {
      setSelectedFirms([]);
    } else {
      setSelectedFirms(
        firmClientsData?.map(({ firm_id }) => Number(firm_id)) || []
      );
    }
    setRemovedFirms(originallyAllowedFirms);
  };

  const saveData = (
    onSave: (
      usersToAdd: UserValue[],
      usersToRemove: UserValue[],
      firmsToAdd: FirmData[],
      firmsToRemove: FirmData[]
    ) => void
  ) => {
    const usersToAdd: UserValue[] = investorList
      .filter(
        ({ id }) =>
          selectedUsers.includes(id) &&
          (!originallyAllowedUsers.includes(id) || removedUsers.includes(id))
      )
      .map(({ id, label, email }) => ({
        value: id,
        label,
        email,
      }));

    const usersToRemove: UserValue[] = investorList
      .filter(
        ({ id }) =>
          !selectedUsers.includes(id) && originallyAllowedUsers.includes(id)
      )
      .map(({ id, label, email }) => ({
        value: id,
        label,
        email,
      }));

    const firmsToAdd: FirmData[] =
      firmClientsData
        ?.filter(
          ({ firm_id }) =>
            selectedFirms.includes(Number(firm_id)) &&
            (!originallyAllowedFirms.includes(Number(firm_id)) ||
              removedFirms.includes(Number(firm_id)))
        )
        .map(({ firm_id }) => ({
          firmId: Number(firm_id),
          skippedUsers: [],
        })) || [];

    const firmsToRemove: FirmData[] =
      firmClientsData
        ?.filter(
          ({ firm_id }) =>
            !selectedFirms.includes(Number(firm_id)) &&
            originallyAllowedFirms.includes(Number(firm_id))
        )
        .map(({ firm_id }) => ({
          firmId: Number(firm_id),
          skippedUsers: [],
        })) || [];

    onSave(usersToAdd, usersToRemove, firmsToAdd, firmsToRemove);
  };

  const hasPartialPermissions = (
    files: (Folder | FileItem)[],
    id: number,
    isFirm: boolean,
    onlyChecked: boolean
  ): number => {
    const totalFiles = files.filter(
      (sf) =>
        !onlyChecked ||
        sf.permissions?.some((p) => {
          if (isFirm) {
            return p.allowedFirms?.some((f) => f === id);
          }
          return p.allowed_users_by_user_name?.some(
            (u) => !onlyChecked || u.user_id === id
          );
        })
    ).length;

    const descendantsChecked = (files || [])
      .map((f) => hasPartialPermissions(f.files || [], id, isFirm, onlyChecked))
      .reduce((total, amount) => total + amount, 0);
    return totalFiles + descendantsChecked;
  };

  const getPermissionIcon = (id: number, isFirm: boolean): IconDefinition => {
    const isSelected = isFirm
      ? selectedFirms.includes(id)
      : selectedUsers.includes(id);
    const isRemoved = isFirm
      ? removedFirms.includes(id)
      : removedUsers.includes(id);
    const isOriginallyAllowed = isFirm
      ? originallyAllowedFirms.includes(id)
      : originallyAllowedUsers.includes(id);

    if (!isSelected) {
      return faSquare;
    }

    if (!isRemoved && !isOriginallyAllowed) {
      if ((folderOrFile as FileItem).files?.some((f) => f.type === 'folder')) {
        return faSquareMinus;
      }
      return faSquareCheck;
    }

    const total = hasPartialPermissions(
      (folderOrFile as Folder).files || [],
      id,
      isFirm,
      false
    );
    const descendantsChecked =
      isRemoved && isOriginallyAllowed
        ? total
        : hasPartialPermissions(
            (folderOrFile as Folder).files || [],
            id,
            isFirm,
            true
          );

    return descendantsChecked === total ? faSquareCheck : faSquareMinus;
  };

  return {
    selectedUsers,
    selectedFirms,
    removedUsers,
    removedFirms,
    handleShareAllFirms,
    handleShareAllUsers,
    toggleUserSelection,
    toggleFirmSelection,
    getPermissionIcon,
    hasPartialPermissions,
    saveData,
    isLoading,
    investorList,
    setSelectedUsers,
    setSelectedFirms,
    setRemovedFirms,
    setRemovedUsers,
    originallyAllowedFirms,
    originallyAllowedUsers,
  };
};
