import { useMemo, useState } from 'react';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import FolderIconFull from '@heroicons/react/24/solid/FolderIcon';
import type {
  Client,
  FileItem,
  Folder,
  TeamFolder,
  FirmData,
  FirmClients,
} from '@types';
import { Button, Menu } from 'react-daisyui';

import { FilePath } from '@/components/FileBrowser/utils/FileUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudUpload,
  faLink,
  faEye,
  faQuestion,
  faCheckDouble,
} from '@fortawesome/free-solid-svg-icons';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { slugify } from '@/utils/string';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { useLocation } from 'react-router-dom';
import { Spinner } from '@/components/Spinner';
import useFileData from '@/hooks/useFileData';
import ShareNotifications from '@/components/FileBrowser/modals/share/ShareNotifications';
import { UserValue } from '../modals/share/SharePermission';
import {
  AcceptPermission,
  RequestPermission,
  GrantPermissionModal,
} from '../modals';
import { clipLastFolder, updateFilePermissions } from '../utils/utils';

import {
  FileSelectAction,
  FolderSelectAction,
  FileSearchItem,
  isFileSearchItem,
} from '../types';

const iconClasses = 'w-6 mr-2 flex-shrink-0 text-accent';

function FolderComponent({
  folder,
  onFolderSelect,
  openUpload,
  currentFolderPath,
  firmClientsData,
  firmUserIds,
}: {
  folder: Folder | FileSearchItem | TeamFolder;
  // eslint-disable-next-line react/no-unused-prop-types
  onFileSelect?: FileSelectAction;
  onFolderSelect?: FolderSelectAction;
  openUpload?: (folder: Folder) => void;
  currentFolderPath: string;
  // eslint-disable-next-line react/no-unused-prop-types
  currentFolderIdPath: string;
  firmClientsData?: FirmClients[];
  firmUserIds?: number[];
}) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext();
  const [showAskFolderAccess, setShowAskFolderAcces] = useState(false);
  const [showAcceptFolderAccess, setShowAcceptFolderAccess] = useState(false);
  const [showGrantPermissionFolder, setShowGrantPermissionFolder] =
    useState<boolean>(false);
  const [showSharePermission, setShowSharePermission] =
    useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<UserValue[]>([]);
  const [selectedFirms, setSelectedFirms] = useState<FirmData[]>([]);
  const { getFileData, refreshFileData } = useFileData();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const thirdPartyId = params.get('third-party') || null;
  const fileId = params.get('file-id') || null;
  if (fileId) {
    params.delete('third-party');
    params.delete('file-id');
    window.history.replaceState({}, '', decodeURI(window.location.pathname));
  }

  const showAcceptButton =
    auth?.isClient && !!thirdPartyId && !!fileId && fileId === folder.id;
  const openFolder = (
    url: string,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    window.open(url);
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  const clientList: Client[] = useMemo(
    () => auth?.clients as Client[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth]
  );

  const getClientId = (teamId: string) =>
    clientList.find((c) => c.ms_team_id === teamId)?.id ||
    impersonatingAccountId;
  const clientId = getClientId(folder.teamId) as number;

  const { data: rootFolder, isValidating: isLoadingFileData } =
    getFileData(clientId);

  const showFileUploadModal = (
    folderToShow: Folder,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (openUpload) {
      openUpload(folderToShow);
    }
  };

  const toggleAcceptAccessModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    setShowAcceptFolderAccess(!showAcceptFolderAccess);
  };

  const toggleAskFolderAccessModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShowAskFolderAcces(!showAskFolderAccess);
    return onFolderSelect ? () => onFolderSelect(folder) : undefined;
  };

  const addUserPermissions = async (
    usersToAdd: UserValue[],
    usersToRemove: UserValue[],
    firmsToAdd: FirmData[],
    firmsToRemove: FirmData[]
  ) => {
    setSelectedUsers(usersToAdd);
    setSelectedFirms(firmsToAdd);
    let error = false;
    try {
      const clientFolders = rootFolder?.files[0] as FileItem;
      await updateFilePermissions(
        usersToAdd,
        usersToRemove,
        firmsToAdd,
        firmsToRemove,
        folder,
        clientFolders,
        clientId
      );
    } catch {
      error = true;
    } finally {
      setShowGrantPermissionFolder(false);
      if (usersToAdd.length > 0) {
        setShowSharePermission(true);
      }
    }
    if (!error) {
      alertMessageSuccess('Permission granted.');
    } else {
      alertErrorMessage('Something went wrong. Please try again.');
    }
    await refreshFileData();
  };

  const rootAndPermissionssEmptyFolder =
    !!(folder as TeamFolder).description &&
    (folder as Folder)?.permissions?.filter((p) =>
      p.allowedUsers.includes(auth?.id as number)
    )?.length === 0;
  const blockedForThirdParty =
    auth?.isInvestor &&
    (!folder.userHasPermission || rootAndPermissionssEmptyFolder);
  const investorWithoutImpersonatingId =
    auth?.isInvestor && !impersonatingAccountId;
  const showShareOptions =
    auth?.isClient || (auth?.isAdmin && impersonatingAccountId);

  const isInvestorReportingFolder =
    folder?.name === 'Investor Reporting' ||
    currentFolderPath.includes('investor-reporting');

  if (isLoadingFileData) {
    return <Spinner />;
  }
  return (
    <>
      <Menu.Item
        onClick={
          onFolderSelect && !blockedForThirdParty
            ? () => onFolderSelect(folder)
            : undefined
        }
        data-testid='folder-item'
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`${
            blockedForThirdParty ? 'pointer-events-none cursor-not-allowed' : ''
          }`}
        >
          {(folder as Folder)?.files?.length === 0 && (
            <FolderIcon
              className={`${iconClasses} ${
                blockedForThirdParty ? 'opacity-50' : ''
              }`}
              data-testid='folder-empty'
            />
          )}
          {(folder as Folder)?.files?.length > 0 && (
            <FolderIconFull
              className={`${iconClasses} ${
                blockedForThirdParty ? 'opacity-50' : ''
              }`}
              data-testid='folder-content'
            />
          )}
          <span className={`${blockedForThirdParty ? 'opacity-50' : ''}`}>
            {folder.name}
          </span>
          {isFileSearchItem(folder) ? (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            <FilePath path={clipLastFolder(folder.path)} />
          ) : null}
          <div className='right-4 absolute flex justify-end w-1/2'>
            {auth?.isClient && showAcceptButton ? (
              <div className='contents'>
                <Button
                  onClick={(e) => {
                    toggleAcceptAccessModal(e);
                  }}
                  size='xs'
                  className='btn-accent ml-2'
                  data-testid='accept-permission-button'
                >
                  <FontAwesomeIcon
                    icon={faCheckDouble}
                    size='lg'
                    title='Accept permissions request'
                  />
                </Button>
                <AcceptPermission
                  isOpen={showAcceptFolderAccess}
                  clientId={impersonatingAccountId || auth?.clients?.[0]?.id}
                  thirdPartyId={Number(thirdPartyId)}
                  onClose={() => {
                    setShowAcceptFolderAccess(false);
                  }}
                  fileItem={folder}
                />
              </div>
            ) : null}
            {showShareOptions && !isInvestorReportingFolder ? (
              <Button
                size='xs'
                data-testid='user-access-button'
                variant='outline'
                className={`${blockedForThirdParty ? 'hidden' : ''}
            menu-hover btn-accent group cursor-pointer ml-2`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowGrantPermissionFolder(true);
                }}
              >
                <FontAwesomeIcon
                  data-testid='grant-permissions-modal'
                  icon={faEye}
                  size='lg'
                  className='text-accent'
                />
              </Button>
            ) : null}
            {folder?.webUrl ? (
              <>
                {!auth?.isInvestor ? (
                  <Button
                    onClick={(e) => showFileUploadModal(folder as Folder, e)}
                    size='xs'
                    data-testid='file-upload-button'
                    variant='outline'
                    className={`btn-accent ml-2 ${
                      blockedForThirdParty ? 'hidden' : ''
                    }`}
                  >
                    <FontAwesomeIcon
                      data-testid='upload-button'
                      icon={faCloudUpload}
                      size='lg'
                      id={`${folder.id}_upload_button`}
                      title='Upload Files'
                      aria-labelledby={`${folder.id}_upload_button`}
                    />
                  </Button>
                ) : null}
                {auth?.isAdmin ? (
                  <Button
                    onClick={(e) => {
                      if (!blockedForThirdParty) {
                        openFolder(folder.webUrl as string, e);
                      } else {
                        e.stopPropagation();
                      }
                    }}
                    size='xs'
                    className={`btn-accent ml-2 ${
                      blockedForThirdParty ? 'hidden' : ''
                    }`}
                  >
                    <FontAwesomeIcon
                      data-testid='opendrive-button'
                      icon={faLink}
                      size='lg'
                      title='Open OneDrive Folder'
                    />
                  </Button>
                ) : null}
                {auth?.isInvestor &&
                blockedForThirdParty &&
                (!investorWithoutImpersonatingId ||
                  rootAndPermissionssEmptyFolder) ? (
                  <>
                    <Button
                      onClick={(e) => {
                        toggleAskFolderAccessModal(e);
                      }}
                      size='xs'
                      data-testid='request-permission-button'
                      className='btn-accent ml-2 pointer-events-auto'
                    >
                      <FontAwesomeIcon
                        data-testid='opendrive-button'
                        icon={faQuestion}
                        size='lg'
                        title='Request permissions'
                      />
                    </Button>
                    <RequestPermission
                      auth={auth}
                      isOpen={showAskFolderAccess}
                      clientId={clientId}
                      onClose={() => setShowAskFolderAcces(false)}
                      fileItem={
                        !rootAndPermissionssEmptyFolder ? folder : undefined
                      }
                      filePath={
                        !rootAndPermissionssEmptyFolder
                          ? currentFolderPath
                          : undefined
                      }
                    />
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </a>
      </Menu.Item>
      {showShareOptions ? (
        <>
          <GrantPermissionModal
            auth={auth}
            clientId={impersonatingAccountId || auth?.clients?.[0]?.id || 0}
            isOpen={showGrantPermissionFolder}
            onClose={() => {
              setShowGrantPermissionFolder(false);
            }}
            folderOrFile={folder}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSave={addUserPermissions}
            firmClientsData={firmClientsData}
            firmUserIds={firmUserIds}
          />
          {showSharePermission ? (
            <ShareNotifications
              auth={auth}
              isOpen={showSharePermission}
              onClose={() => setShowSharePermission(false)}
              currentPath={`${currentFolderPath}/${slugify(folder.name)}`}
              name={folder.name}
              clientId={impersonatingAccountId || auth?.clients?.[0]?.id}
              item={folder}
              itemType='file'
              newUsers={selectedUsers}
              newFirms={selectedFirms}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}

export default FolderComponent;
