import { useLocation } from 'react-router-dom';
import { InvestorReportDTO, InvestorReportTemplateDTO } from '@types';
import { useState, useMemo } from 'react';
import { InvestorReportingBaseTableView } from '@/pages/InvestorReporting/components/InvestorReportingBaseTableView';
import InvestorReportingPastUpdates from '@/pages/InvestorReporting/components/tables/InvestorReportingPastUpdates';
import InvestorReportingPastUpdatesV2 from '@/pages/InvestorReporting/components/tables/InvestorReportingPastUpdatesV2';

interface Props {
  investorReports: InvestorReportDTO[];
  investorReportTemplates: InvestorReportTemplateDTO[];
  isV2?: boolean;
}

export function PastUpdates({
  investorReports,
  investorReportTemplates,
  isV2 = false,
}: Props) {
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const clientNameFromQuery = queryParams.get('clientName') || '';
  const [searchText, setSearchText] = useState<string>('');

  const filteredReports = useMemo(() => {
    return investorReports.filter((report) => {
      const matchesClientName = clientNameFromQuery
        ? report.client?.client_name
            ?.toLowerCase()
            .includes(clientNameFromQuery.toLowerCase())
        : true;

      const matchesSearchText = report.client?.client_name
        ?.toLowerCase()
        .includes(searchText.toLowerCase());

      return matchesClientName && matchesSearchText;
    });
  }, [investorReports, searchText, clientNameFromQuery]);

  return (
    <div className='mb-4'>
      <InvestorReportingBaseTableView
        searchText={searchText}
        setSearchText={setSearchText}
        showAllReports={!!clientNameFromQuery}
        placeholder='Search for client name'
        title=''
      >
        {isV2 ? (
          <InvestorReportingPastUpdatesV2
            investorReports={filteredReports}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
            searchText={searchText}
            investorReportTemplates={investorReportTemplates}
          />
        ) : (
          <InvestorReportingPastUpdates
            investorReports={filteredReports}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
            searchText={searchText}
            investorReportTemplates={investorReportTemplates}
          />
        )}
      </InvestorReportingBaseTableView>
    </div>
  );
}
