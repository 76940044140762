import { useLocation } from 'react-router-dom';
import {
  InvestorReportDTO,
  JWTUser,
  InvestorReportTemplateDTO,
  ExtendedInvestorReportCardDTO,
} from '@types';
import { useState, useMemo } from 'react';
import InvestorClientListV2 from '@/pages/InvestorReporting/components/Investors/v2/InvestorClientListV2';
// eslint-disable-next-line max-len
import { OpenCardsForClients } from '@/pages/InvestorReporting/components/Clients/v2/table/OpenCardsForClients';
import { RequestListForClients } from '@/pages/InvestorReporting/components/Clients/v2/table/RequestListForClients';
import { InvestorReportingBaseTableView } from './components/InvestorReportingBaseTableView';

interface Props {
  investorReports: InvestorReportDTO[];
  user?: JWTUser | null;
  allInvestorReports: InvestorReportDTO[];
  investorReportTemplates: InvestorReportTemplateDTO[];
  impersonatingAccountId: number | null;
  updateData: () => Promise<void>;
}

export function InvestorReportingManagementV2({
  investorReports,
  user,
  allInvestorReports,
  investorReportTemplates,
  impersonatingAccountId,
  updateData,
}: Props) {
  const [cardSearchText, setCardSearchText] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const clientNameFromQuery = queryParams.get('clientName') || '';
  const showAllReports = queryParams.get('showAllReports');

  const filterReports = (reports: InvestorReportDTO[], searchKey: string) => {
    return reports.filter((report) => {
      const matchesClientName = clientNameFromQuery
        ? report.client?.client_name
            ?.toLowerCase()
            .includes(clientNameFromQuery.toLowerCase())
        : true;

      const matchesSearchText = report.client?.client_name
        ?.toLowerCase()
        .includes(searchKey.toLowerCase());

      return matchesClientName && matchesSearchText;
    });
  };

  const flattenOpenCards = (
    reports: InvestorReportDTO[]
  ): ExtendedInvestorReportCardDTO[] => {
    return reports.flatMap((report) => {
      return (report.investor_report_cards || [])
        .filter((card) => card.status === 'open')
        .map((card) => {
          return {
            ...card,
            firm: report.firm,
            user: report.user,
            parentReportId: report.id,
          };
        });
    });
  };

  const openCards = flattenOpenCards(
    showAllReports ? allInvestorReports : investorReports
  );

  const reportsForClients = useMemo(() => {
    const reports = showAllReports ? allInvestorReports : investorReports;
    return filterReports(reports, searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    investorReports,
    allInvestorReports,
    showAllReports,
    searchText,
    clientNameFromQuery,
  ]);

  const canViewOpenCards =
    user?.isClient || (impersonatingAccountId && user?.isAdmin);

  return (
    <>
      <div className={`${canViewOpenCards ? 'mb-4' : ''}`}>
        {user?.isClient || (impersonatingAccountId && user?.isAdmin) ? (
          <InvestorReportingBaseTableView
            searchText={cardSearchText}
            setSearchText={setCardSearchText}
            showAllReports={!!showAllReports}
            title='Open Cards'
            placeholder='Search Cards'
          >
            <OpenCardsForClients
              openCards={openCards}
              recordsPerPage={recordsPerPage}
              selectedPage={selectedPage}
              onPageSelect={setSelectedPage}
              onSelectRowsChange={setRecordsPerPage}
              searchText={cardSearchText}
              refreshData={updateData}
            />
          </InvestorReportingBaseTableView>
        ) : null}
      </div>
      <InvestorReportingBaseTableView
        searchText={searchText}
        setSearchText={setSearchText}
        showAllReports={!!showAllReports}
        title={`${user?.isClient ? 'Connected Investors' : ''}`}
        placeholder='Search Reports'
      >
        {user?.isInvestor ? (
          <InvestorClientListV2
            investorReports={reportsForClients}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
            searchText={searchText}
            investorReportTemplates={investorReportTemplates}
            updateData={updateData}
          />
        ) : null}
        {user?.isClient || (impersonatingAccountId && user?.isAdmin) ? (
          <RequestListForClients
            investorReports={investorReports || []}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
            searchText={searchText}
            investorReportTemplates={investorReportTemplates}
          />
        ) : null}
      </InvestorReportingBaseTableView>
    </>
  );
}
